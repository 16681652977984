<template>
    <nav class="navbar navbar-expand navbar-light navbar-bg">
        <a class="sidebar-toggle js-sidebar-toggle" @click="initializeSidebarCollapse()" v-if="getAuthStatus">
            <i class="hamburger align-self-center"></i>
        </a>
        
      
        <a class="sidebar-toggle js-sidebar-toggle" v-else>
            <i class="hamburger align-self-center"></i>
        </a>
        <router-link class="text-subtitle-1" to="/">
                <span class="align-center" style="color: black; font-weight: normal; margin: 0;">Job Application Portal</span>
        </router-link>
        <div class="navbar-collapse collapse">
            <ul class="navbar-nav navbar-align">
                <!--li class="nav-item dropdown">
                    <a class="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
                        <div class="position-relative">
                            <i class="align-middle" data-feather="bell"></i>
                            <span class="indicator">4</span>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="alertsDropdown">
                        <div class="dropdown-menu-header">
                            4 New Notifications
                        </div>
                        <div class="list-group">
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <i class="text-danger" data-feather="alert-circle"></i>
                                    </div>
                                    <div class="col-10">
                                        <div class="text-dark">Update completed</div>
                                        <div class="text-muted small mt-1">Restart server 12 to complete the update.</div>
                                        <div class="text-muted small mt-1">30m ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <i class="text-warning" data-feather="bell"></i>
                                    </div>
                                    <div class="col-10">
                                        <div class="text-dark">Lorem ipsum</div>
                                        <div class="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit
                                            et.</div>
                                        <div class="text-muted small mt-1">2h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <i class="text-primary" data-feather="home"></i>
                                    </div>
                                    <div class="col-10">
                                        <div class="text-dark">Login from 192.186.1.8</div>
                                        <div class="text-muted small mt-1">5h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <i class="text-success" data-feather="user-plus"></i>
                                    </div>
                                    <div class="col-10">
                                        <div class="text-dark">New connection</div>
                                        <div class="text-muted small mt-1">Christina accepted your request.</div>
                                        <div class="text-muted small mt-1">14h ago</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-menu-footer">
                            <a href="#" class="text-muted">Show all notifications</a>
                        </div>
                    </div>
                </li-->
                <!--li class="nav-item dropdown">
                    <a class="nav-icon dropdown-toggle" href="#" id="messagesDropdown" data-bs-toggle="dropdown">
                        <div class="position-relative">
                            <i class="align-middle" data-feather="message-square"></i>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="messagesDropdown">
                        <div class="dropdown-menu-header">
                            <div class="position-relative">
                                4 New Messages
                            </div>
                        </div>
                        <div class="list-group">
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <img :src="require('@/assets/img/avatars/avatar-5.jpg')"
                                            class="avatar img-fluid rounded-circle" alt="Vanessa Tucker">
                                    </div>
                                    <div class="col-10 ps-2">
                                        <div class="text-dark">Vanessa Tucker</div>
                                        <div class="text-muted small mt-1">Nam pretium turpis et arcu. Duis arcu tortor.
                                        </div>
                                        <div class="text-muted small mt-1">15m ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <img :src="require('@/assets/img/avatars/avatar-2.jpg')"
                                            class="avatar img-fluid rounded-circle" alt="William Harris">
                                    </div>
                                    <div class="col-10 ps-2">
                                        <div class="text-dark">William Harris</div>
                                        <div class="text-muted small mt-1">Curabitur ligula sapien euismod vitae.</div>
                                        <div class="text-muted small mt-1">2h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <img :src="require('@/assets/img/avatars/avatar-4.jpg')"
                                            class="avatar img-fluid rounded-circle" alt="Christina Mason">
                                    </div>
                                    <div class="col-10 ps-2">
                                        <div class="text-dark">Christina Mason</div>
                                        <div class="text-muted small mt-1">Pellentesque auctor neque nec urna.</div>
                                        <div class="text-muted small mt-1">4h ago</div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="list-group-item">
                                <div class="row g-0 align-items-center">
                                    <div class="col-2">
                                        <img :src="require('@/assets/img/avatars/avatar-3.jpg')"
                                            class="avatar img-fluid rounded-circle" alt="Sharon Lessman">
                                    </div>
                                    <div class="col-10 ps-2">
                                        <div class="text-dark">Sharon Lessman</div>
                                        <div class="text-muted small mt-1">Aenean tellus metus, bibendum sed, posuere ac,
                                            mattis non.</div>
                                        <div class="text-muted small mt-1">5h ago</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-menu-footer">
                            <a href="#" class="text-muted">Show all messages</a>
                        </div>
                    </div>
                </-->
                <li class="nav-item" v-if="getAuthStatus">
                    

                    <a class="nav-ico dropdown-toggle d-none d-sm-inline-block" href="#" data-bs-toggle="dropdown">
                        <v-avatar color="red" >
                            <span> {{ getCurrentUser[0].first_name.substring(0,1) }} {{ getCurrentUser[0].last_name.substring(0,1) }}</span>
                        </v-avatar>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                        <!--a class="dropdown-item" href="pages-profile.html"><i class="align-middle me-1"
                                data-feather="user"></i> Profile</a>
                        <a class="dropdown-item" href="#"><i class="align-middle me-1" data-feather="pie-chart"></i>
                            Analytics</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="index.html"><i class="align-middle me-1" data-feather="settings"></i>
                            Settings & Privacy</a>
                        <a class="dropdown-item" href="#"><i class="align-middle me-1" data-feather="help-circle"></i> Help
                            Center</a>
                        <div class="dropdown-divider"></div-->
                        <a class="dropdown-item" href="#" @click="logOut()">Log out</a>
                    </div>
                   
                </li>
                <li class="nav-item" v-if="!getAuthStatus">
                    <router-link to="/signin">Sign in</router-link>
                </li>
            </ul>
        </div>
    </nav>
   
</template>
<script>
 /* eslint-disable */ 
 import feather from "feather-icons";
 import { mapActions, mapGetters } from "vuex";
export default {
    name: 'ApplicationBar',
    data: () => ({
        isAuth: false,
    }),
    computed: {
        ...mapGetters(["getAuthStatus", "getCurrentUser"]),
    },
    created() {
        this.checkAuth()
        .then((resp) => {
            try {
                feather.replace();
            }
            catch(e) {
                console.log('You might have made a typo with one of the feather icons');
                console.log(e);
            }
        })
        .catch((err) => {

        })
       
    },
    mounted() {
        
    },
    methods: {
        ...mapActions(["checkAuth", "signOut"]),
        initializeSidebarCollapse(){
           
            const sidebarElement = document.getElementsByClassName("js-sidebar")[0];
            sidebarElement.classList.toggle("collapsed");
            if(sidebarElement) {
                sidebarElement.addEventListener("transitionend", () => {
                    window.dispatchEvent(new Event("resize"));
                });
            }
        },
        
        logOut(){
            this.signOut()
            .then((resp)=>{
                    this.$router.push('/')
            })
            .catch((err)=>{
                this.$router.push('/')
            })
        }
    }
}
</script>
