import { defineComponent } from "vue";

// Components
/* eslint-disable */
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "HomeView",

  components: {},
  data: () => ({
    dataLoading: false,
    searchLoading: false,
    search: "",
    pagination: {
      current_page: 1,
      last_page: 1,
      visible: 6,
    },
    headers: [
     
      { title: "Position Title", key: "title",  },
      { title: "Place of Assignment", key: "DeptDesc",  },
      { title: "Vacant", key: "noofvacancy",  },
      { title: "Posting Date", key: "posting_date",  },
      { title: "Closing Date", key: "closing_date",  },
      { title: "", key: "action",  },
    ],
    items: [],
    inputTimer: null,
    queryParams: "",
    hideHeader: false,
   
  }),
  computed: {
    ...mapGetters(["getAuthStatus", "getCurrentUser"]),
  },

  created() {
    this.checkAuth()
      .then((resp) => {})
      .catch((err) => {});
  },
  mounted() {
    this.FetchVacancy();
    this.hasQueryParam();
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
  },
  unmounted(){
    window.removeEventListener('resize', this.updateScreenWidth);
  },
  methods: {
    ...mapActions(["checkAuth"]),
    updateScreenWidth(){
      let sw = window.innerWidth;
      if(sw  > 1279){
        this.hideHeader = false;
      }else{
        this.hideHeader = true;
      }
     
    },
    
    hasQueryParam() {
      let hasQuery = this.$route.query.hasOwnProperty("q");
      if (hasQuery) {
        this.queryParams = 1;
      } else {
        this.queryParams = 0;
      }
    },
    ViewAllJobVacancies() {
      window.location.replace("/");
    },
    getCurrentDate() {
      const currentDate = new Date();

      // Extract components
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to get the correct month and pad with 0 if necessary
      const day = currentDate.getDate().toString().padStart(2, "0"); // Pad with 0 if necessary

      // Create the formatted date string
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    isClosed(closingdates) {
      let validate = false;
      let closingdate = new Date(closingdates).getTime();
      let currentDate = new Date(this.getCurrentDate()).getTime();
      if (closingdate >= currentDate) {
        validate = false;
      } else {
        validate = true;
      }
      return validate;
    },
    GetJobDetails(id) {
      this.$parent.$parent.$refs.PreLoader.showPreLoader();
      this.$router.push({ path: "/jobdetails", query: { jd: id } });
    },
    FetchVacancySearch() {
      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(() => {
        this.searchLoading = true;
        this.FetchVacancy();
      }, 500); // Will do the ajax stuff after 500 ms, or 1 s
    },

    FetchVacancy() {
      let q = "";
      if (this.$route.query.q) {
        q = this.$route.query.q;
      }
      this.dataLoading = true;
      axios({
        method: "get",
        url: process.env.VUE_APP_API + "careers/FetchVacancy",

        params: {
          search: this.search,
          q: q,
        },
      })
        .then((resp) => {
          if (this.$route.query.q) {
           
            this.items = resp.data;
          
            this.pagination = {
              current_page: 1,
              last_page: 1,
              visible: 6,
            };
          } else {
            this.items = resp.data.data;
            this.pagination.last_page = resp.data.last_page;
          }

          this.dataLoading = false;
          this.searchLoading = false;
        })
        .catch((err) => {
          this.dataLoading = false;
          this.searchLoading = false;
        });
    },
    FetchVacancyPage() {
      this.dataLoading = true;
      axios({
        method: "get",
        url: process.env.VUE_APP_API + "careers/FetchVacancy",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ua"),
        },
        params: {
          search: this.search,
          page: this.pagination.current_page,
        },
      })
        .then((resp) => {
          this.items = resp.data.data;
          this.pagination.last_page = resp.data.last_page;
          this.dataLoading = false;
          this.searchLoading = false;
        })
        .catch((err) => {
          this.dataLoading = false;
          this.searchLoading = false;
        });
    },
  },
});
