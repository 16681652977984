<template>
  <div class="card p-0" v-if="!getAuthStatus">
    <div class="card-header font-weight-bold">

      <div class="row">
        <div class="col-sm-6">
          <div class="fs-3 mt-2">
            Job Vacancies
          </div>
        </div>
        <div class="col-sm-6 d-flex flex-row-reverse">
          <v-text-field v-model="search" :loading="searchLoading" density="compact" variant="solo"
            label="Search Vacancies" append-inner-icon="mdi-magnify" single-line hide-details color="primary"
            @update:modelValue="FetchVacancySearch()" @click:append-inner="FetchVacancySearch()"></v-text-field>
        </div>
      </div>
    </div>

    <div class="card-body">
     
      <v-progress-linear color="teal" :loading="dataLoading" :indeterminate="dataLoading" absolute></v-progress-linear>

      <v-data-table :headers="headers" :items="items" :mobile="null" :disable-sort="true" :hide-default-header="hideHeader" :hide-default-footer="true" items-per-page="-1">
        <template v-slot:headers="{ columns }">
          <tr class="v-data-table__tr">
            <th class="v-data-table__td v-data-table-column--align-start font-weight-bold" v-for="column in columns" :key="column.key">{{ column.title }} </th>
          </tr>
          
        </template>

        <template v-slot:[`item.title`]="{ item }">
          {{ item.title }}
        </template>

        <template v-slot:[`item.DeptDesc`]="{ item }">
          {{ item.DeptDesc }}
        </template>

        <template v-slot:[`item.noofvacancy`]="{ item }">
          {{ item.noofvacancy }}
        </template>

        <template v-slot:[`item.posting_date`]="{ item }">
          {{ item.posting_date }}
        </template>

        <template v-slot:[`item.closing_date`]="{ item }">
          <v-chip variant="flat" color="red" v-if="isClosed(item.closing_date)" class="mr-1">
                  Already Closed
                </v-chip>
          <span v-else>{{ item.closing_date }}</span>
          
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn class="mr-1 text-none" prepend-icon="mdi-folder" size="small" rounded="xl" color="primary" flat
            @click="GetJobDetails(item.id)">
            View Details
          </v-btn>
        </template>
       
       

      </v-data-table>
      
      <div class="row">
        <div class="col-sm-6"></div>
        <div class="col-sm-6 d-flex flex-row-reverse" v-if="queryParams == 0">
          <v-pagination v-model="pagination.current_page" :length="pagination.last_page"
            :total-visible="pagination.visible" color="primary" density="compact" elevation="1"
            @update:modelValue="FetchVacancyPage()"></v-pagination>
        </div>
        <div class="col-sm-6 d-flex flex-row-reverse" v-if="queryParams == 1">
          <v-btn class="mr-1 text-none" size="small" rounded="xl" color="green-darken-4" flat
            @click="ViewAllJobVacancies()">
            View All Job Vacancies
          </v-btn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HomeView from './HomeView.js';
export default HomeView;
</script>
<style>
html {
  overflow-y: auto
}
</style>