/* eslint-disable */
import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: 0
    },
    component: HomeView,
    
  },
  {
    path: '/signin',
    name: 'signin',
    meta: {
      auth: 0
    },
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    meta: {
      auth: 0
    },
    component: () => import('../views/ForgotPasswordView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      auth: 0
    },
    component: () => import('../views/SignUpView.vue')
  },
 
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      auth: 1
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
  },
  
  {
    path: '/profileinfo',
    name: 'profileinfo',
    meta: {
      auth: 1
    },
    component: () => import('../views/ProfileInfoView.vue')
  },

  {
    path: '/jobdetails',
    name: 'jobdetails',
    meta: {
      auth: 0
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/JobDetailsView.vue')
  },

  {
    path: '/jobdetails2',
    name: 'jobdetails2',
    meta: {
      auth: 1
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/JobDetails2View.vue')
  },
  
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



let checkRoles = function (rolesList, roles) {
  let role = rolesList.filter(d => d.role_id == roles)

  if (role.length > 0) {

    if (role[0].permissions == 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

let signOut = function () {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: process.env.VUE_APP_API + "auth/logout",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ua")
      },

    })
      .then((resp) => {
        resolve("1");
      })
      .catch((err) => {
        reject("0");
      })
  })
}
let checkAuth = (to) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: process.env.VUE_APP_API + "auth/me",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ua")
      },
    })
      .then((resp) => {
        if (to.name == 'home') {
          resolve(1)
        } else {
          resolve(2)
        }
      })
      .catch((err) => {
        if (err) {
          reject(0)
        }
      })
  })
}
 
router.beforeEach((to, from, next) => {
  //console.log(to.meta.auth)
 
  if (localStorage.getItem('ua')) {
    checkAuth(to)
        .then((resp) => {
          if (resp == 1) {
            next({ name: 'dashboard' });
          } else {
            if(to.meta.auth == 0){
              next({ name: 'dashboard' });
            }else{
              next();
            }
           
          }
        }).catch((err) => {
          localStorage.removeItem('ua')
          if(to.name != 'home'){
            next({ name: 'home' });
          }
        })
  }else{
    if(to.meta.auth == 0){
      next()
    }else{
      next({ name: 'home' });
    }
  
  }
})

export default router
