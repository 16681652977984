<template>
  <PreLoader ref="PreLoader"/>
  <NavigationDrawer ref="NavigationDrawer" />
    <div class="main">
      <ApplicationBar />
      <main class="content">

        <div class="container-fluid p-0">

          <router-view />


        </div>
      </main>
      <FooterView />
    </div>
    

 
  <!--/v-app-->
</template>

<script>
/* eslint-disable */

import { mapActions, mapGetters } from "vuex";
import PreLoader from './components/PreLoader.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';
import ApplicationBar from './components/ApplicationBar.vue';
import FooterView from './components/FooterView.vue';
import feather from "feather-icons";
import './assets/js/app'


export default {
  name: 'App',
  components: {
    PreLoader,
    NavigationDrawer,
    ApplicationBar,
    FooterView,
  },
  data: () => ({
    //
    x: '',
  }),
  created(){
    this.checkAuth().then((resp) => {
      try {
        feather.replace();
      }
      catch(e) {
        console.log('You might have made a typo with one of the feather icons');
        console.log(e);
      }
    }).catch((err) => {})
  },
  mounted(){
    
   
  },
  computed: {
        ...mapGetters(["getAuthStatus", "getCurrentUser"]),
  },
  methods: {
    ...mapActions(["checkAuth"]),
  }
}
</script>
<style>
 html { overflow-y: auto }
</style>
