<template>
    <nav id="sidebar" class="sidebar js-sidebar" v-if="getAuthStatus">
        <div class="sidebar-content js-simplebar" id="js-simplebar">
            <router-link class="sidebar-brand" to="/dashboard">
                <span class="align-middle">Job Application Portal</span>
            </router-link>
 
            <ul class="sidebar-nav">
                <li class="sidebar-header">
                    Pages
                </li>

                <li :class="navitem[0].class">
                    <a class="sidebar-link" @click="setNavigation(0)">
                        <v-icon icon="mdi-view-dashboard"></v-icon>
                        <span class="align-middle">{{ navitem[0].text
                        }}</span>
                    </a>
                </li>
                <li :class="navitem[1].class">
                    <a class="sidebar-link" @click="setNavigation(1)">
                        <v-icon icon="mdi-clipboard-account-outline"></v-icon>
                        <span class="align-middle">{{ navitem[1].text
                        }}</span>
                    </a>
                </li>
               



            </ul>

            <!--div class="sidebar-cta">
                <div class="sidebar-cta-content">
                    <strong class="d-inline-block mb-2">Upgrade to Pro</strong>
                    <div class="mb-3 text-sm">
                        Are you looking for more components? Check out our premium version.
                    </div>
                    <div class="d-grid">
                        <a to="upgrade-to-pro.html" class="btn btn-primary">Upgrade to Pro</a>
                    </div>
                </div>
            </div.-->
        </div>
    </nav>
</template>
<script>
/* eslint-disable */

import SimpleBar from "simplebar";
import feather from "feather-icons";
import { mapActions, mapGetters } from "vuex";


export default {
    name: 'NavigationDrawer',
    
    data: () => ({
        isAuth: false,
        navitem: [
            { text: 'Job Vacancies', link: 'dashboard', class: 'sidebar-item' },
            { text: 'Profile Information', link: 'profileinfo', class: 'sidebar-item' },
          
        ]
    }),
    computed: {
        ...mapGetters(["getAuthStatus", "getCurrentUser"]),
    },
    created() {
       
    },
    mounted() {
        this.checkAuth()
        .then((resp) => {
            this.$nextTick(function () {
                
                this.initNavigation()
                this.initializeSimplebar()
                try {
                    feather.replace();
                }
                catch (e) {
                    console.log('You might have made a typo with one of the feather icons');
                    console.log(e);
                }
            })
        }).catch((err) => { })
        

    },

    methods: {
        ...mapActions(["checkAuth", "signOut"]),
        initNavigation() {
            const currentUrl = window.location.href;
          
            for(let i = 0; i < this.navitem.length; i++){
                this.navitem[i].class = 'sidebar-item'
                if(currentUrl.toLowerCase().includes(this.navitem[i].link)){
                    this.navitem[i].class = 'sidebar-item active'
                }
            }
            
        },
        setNavigation(q) {
            for (let i = 0; i < this.navitem.length; i++) {
                this.navitem[i].class = 'sidebar-item'
            }
            this.navitem[q].class = 'sidebar-item active'
            const currentUrl = window.location.href;
            if(!currentUrl.toLowerCase().includes(this.navitem[q].link)){
                this.$parent.$refs.PreLoader.showPreLoader()
            }
            
            this.$router.push(this.navitem[q].link)
          

        },
        initializeSimplebar() {
            const simplebarElement = document.getElementsByClassName("js-simplebar")[0];

            if (simplebarElement) {

                const simplebarInstance = new SimpleBar(document.getElementsByClassName("js-simplebar")[0]);
                /* Recalculate simplebar on sidebar dropdown toggle */
                const sidebarDropdowns = document.querySelectorAll(".js-sidebar [data-bs-parent]");

                sidebarDropdowns.forEach(link => {
                    link.addEventListener("shown.bs.collapse", () => {
                        simplebarInstance.recalculate();
                    });
                    link.addEventListener("hidden.bs.collapse", () => {
                        simplebarInstance.recalculate();
                    });
                });
            }
        }

    }
}
</script>
