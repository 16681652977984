<template>
    <!-- Preloader -->
  <div class="preloader flex-column justify-content-center align-items-center">
    <img class="animation__shake" :src="require('@/assets/img/logo.png')" alt="AdminLTELogo" height="60" width="60">
  </div>
</template>
<script>
 /* eslint-disable */ 


export default {
    name: 'PreLoader',
    data: () => ({
      
    }),
    computed: {
     
    },
    created() {
       
    },
    mounted() {
        this.hidePreLoader()
    },
    methods: {
      showPreLoader(){
         
          let preloader = document.getElementsByClassName('preloader')[0]
          preloader.style.height = '0vh';
          if (preloader) {
           
              for (let i = 0; i < preloader.children.length; i++) {
                  let child = preloader.children[i];
                    child.style.display = 'block';
              }
              preloader.style.height = '100vh';
             
          }
         
        
      },
      hidePreLoader(){
      
        setTimeout(() => {
          let preloader = document.getElementsByClassName('preloader')[0]
          if (preloader) {
            preloader.style.height = '0vh';
            setTimeout(() => {
              for (let i = 0; i < preloader.children.length; i++) {
                let child = preloader.children[i];
                  child.style.display = 'none';
              }
              
             // preloader.children().hide()
            }, 200)
          }
        }, 200)
      }
    }
}
</script>
<style scoped>
.preloader {
  display: -ms-flexbox;
  display: flex;
  background-color: #f4f6f9;
  height: 100vh;
  width: 100%;
  transition: height 200ms linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.dark-mode .preloader {
  background-color: #454d55 !important;
  color: #fff;
}

.animation__shake {
  -webkit-animation: shake 1500ms;
  animation: shake 1500ms;
}
</style>